import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormControl, Spinner, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import AppContext from 'context/Context';
import { AddUsernameForm as textLang } from 'staticData/languages';

const AddUsernameForm = ({ hasLabel }) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  // State
  const [username, setUsername] = useState();
  const [repeatUsername, setRepeatUsername] = useState();

  const [validUsername, setValidUsername] = useState(true);
  const [validRepeatUsername, setValidRepeatUsername] = useState(true);
  const [repeatUsernameFeedback, setRepeatUsernameFeedback] = useState();
  const [usernameFeedback, setUsernameFeedback] = useState();

  const [changeUsername, setChangeUsername] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const changeUsernameFunction = async () => {
    // Generate Recaptcha Token & Login
    window.grecaptcha.enterprise.ready(async () => {
      const recaptcha_token = await window.grecaptcha.enterprise.execute(
        '6LcE9igoAAAAAJ2plIdhi4bvhKr3hn95kyNmxTqc',
        { action: 'change_username' }
      );
      let form = new FormData();
      form.append('new_username', username.trim());
      form.append('recaptcha_token', recaptcha_token);
      let changeUsernameResponse = await endpointInterface(
        lang,
        'backend',
        'changeUsername',
        'post',
        true,
        form
      );
      if (changeUsernameResponse.validResponse) setChangeUsername(true);
      else {
        var check = true;
        setLoadingButton(false);
        if (changeUsernameResponse.responseMessage.startsWith('USERNAME')) {
          setUsernameFeedback(
            changeUsernameResponse.responseMessage.substring(10)
          );
          setValidUsername(false);
          check = false;
        }
        if (check) {
          toast.error(changeUsernameResponse.responseMessage, {
            closeButton: false
          });
          setTimeout(() => {
            toast.dismiss();
          }, 5000);
        }
      }
    });
  };

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setLoadingButton(true);
    setValidRepeatUsername(true);
    setRepeatUsernameFeedback(textLang.noMatch[lang]);
    setValidUsername(true);
    setUsernameFeedback(textLang.usernameLonger[lang]);
    var valid = true;
    if (
      !username.trim().match('^[A-Za-z0-9]+$') ||
      username.trim().length < 6
    ) {
      setValidUsername(false);
      valid = false;
    }
    if (username === userInfos.username) {
      setUsernameFeedback(textLang.userNotEqual[lang]);
      setValidUsername(false);
      valid = false;
    }
    if (username !== repeatUsername) {
      setValidRepeatUsername(false);
      valid = false;
    }
    if (valid) changeUsernameFunction();
    else setLoadingButton(false);
  };

  return (
    <>
      {Object.keys(userInfos).length === 0 ? (
        <>
          <p className="opacity-75 text-center">{textLang.toChange[lang]}</p>
          <div className="d-flex justify-content-center">
            <Link className="btn btn-primary" to={'/login'}>
              {textLang.goToLogin[lang]}
            </Link>
          </div>
        </>
      ) : (
        <>
          {changeUsername ? null : <h3>{textLang.insNewUsername[lang]}</h3>}
          {!changeUsername ? (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-1">
                {hasLabel && (
                  <Form.Label>{textLang.newUsername[lang]}</Form.Label>
                )}
                <Form.Control
                  placeholder={!hasLabel ? textLang.newUsername[lang] : ''}
                  value={username}
                  name="username"
                  onChange={e => setUsername(e.target.value)}
                  type="text"
                  isInvalid={!validUsername}
                />
                <FormControl.Feedback type="invalid">
                  {usernameFeedback}
                </FormControl.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                {hasLabel && (
                  <Form.Label>{textLang.repUsername[lang]}</Form.Label>
                )}
                <Form.Control
                  placeholder={!hasLabel ? textLang.repUsername[lang] : ''}
                  value={repeatUsername}
                  name="repeat-username"
                  onChange={e => setRepeatUsername(e.target.value)}
                  type="text"
                  isInvalid={!validRepeatUsername}
                />
                <FormControl.Feedback type="invalid">
                  {repeatUsernameFeedback}
                </FormControl.Feedback>
              </Form.Group>

              <Form.Group className="mb-4">
                <Button
                  className="w-100"
                  type="submit"
                  disabled={!repeatUsername || !username || loadingButton}
                >
                  {textLang.setUsername[lang]}
                </Button>
                {loadingButton ? (
                  <Row className="mt-3">
                    <Col
                      md={6}
                      className="gx-1"
                      style={{ margin: '0 auto', textAlign: 'center' }}
                    >
                      <Spinner animation="border" role="status"></Spinner>
                    </Col>
                  </Row>
                ) : null}
              </Form.Group>
            </Form>
          ) : (
            <>
              <p className="opacity-75 text-center">
                {textLang.awesome[lang]} <br /> {textLang.changeusr[lang]}
              </p>
              <div className="d-flex justify-content-center">
                <Link className="btn btn-primary" to="/">
                  Home
                </Link>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

AddUsernameForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default AddUsernameForm;
