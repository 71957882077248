import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormControl, Spinner, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { AddPWDForm as textLang } from 'staticData/languages';
import AppContext from 'context/Context';

const AddPWDForm = ({ hasLabel, uniqueCode }) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  // State
  const [password, setPassword] = useState();
  const [OldPassword, setOldPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const [validPWD, setValidPWD] = useState(true);
  const [validRepeatPWD, setValidRepeatPWD] = useState(true);
  const [validOldPWD, setValidOldPWD] = useState(true);
  const [PWDFeedback, setPWDFeedback] = useState();
  const [repeatPWDFeedback, setRepeatPWDFeedback] = useState();
  const [oldPWDFeedback, setOldPWDFeedback] = useState();
  const [changePWDStatus, setChangePWDStatus] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const changePwd = async () => {
    let form = new FormData();
    uniqueCode
      ? form.append('unique_code', uniqueCode)
      : form.append('old_password', uniqueCode ? '' : OldPassword);
    form.append('new_password', password);
    let loggedChangePwdResponse = await endpointInterface(
      lang,
      'backend',
      uniqueCode ? 'notLoggedInChangePassword' : 'loggedChangePassword',
      'post',
      true,
      form
    );
    if (loggedChangePwdResponse.validResponse) setChangePWDStatus(true);
    else {
      var check = true;
      setLoadingButton(false);
      if (loggedChangePwdResponse.responseMessage.startsWith('OLDPWD')) {
        setOldPWDFeedback(loggedChangePwdResponse.responseMessage.substring(8));
        setValidOldPWD(false);
        check = false;
      }
      if (check) {
        toast.error(loggedChangePwdResponse.responseMessage, {
          closeButton: false
        });
        setTimeout(() => {
          toast.dismiss();
        }, 5000);
      }
    }
  };

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setLoadingButton(true);
    setValidPWD(true);
    setValidRepeatPWD(true);
    setPWDFeedback(textLang.pwdErr[lang]);
    setRepeatPWDFeedback(textLang.passNoMatch[lang]);
    var valid = true;
    let minLen = password.length < 8;
    let num = password.search(/\d/) === -1;
    let lowerCase = password.search(/[a-z]/) == -1;
    let upperCase = password.search(/[A-Z]/) == -1;
    if (minLen || num || lowerCase || upperCase) {
      setValidPWD(false);
      valid = false;
    }
    if (password !== repeatPassword) {
      setValidRepeatPWD(false);
      valid = false;
    }
    if (valid) changePwd();
    else setLoadingButton(false);
  };

  return (
    <>
      {Object.keys(userInfos).length === 0 && !uniqueCode ? (
        <>
          <p className="opacity-75 text-center">{textLang.noLog[lang]}</p>
          <div className="d-flex justify-content-center">
            <Link className="btn btn-primary" to={'/login'}>
              {textLang.goTo[lang]} Login
            </Link>
          </div>
        </>
      ) : (
        <>
          {changePWDStatus ? null : <h3>{textLang.title[lang]}</h3>}
          {!changePWDStatus ? (
            <Form onSubmit={handleSubmit}>
              {!uniqueCode ? (
                <Form.Group className="mb-3">
                  {hasLabel && <Form.Label>{textLang.old[lang]}</Form.Label>}
                  <Form.Control
                    placeholder={!hasLabel ? textLang.old[lang] : ''}
                    value={OldPassword}
                    name="oldPassword"
                    onChange={e => setOldPassword(e.target.value)}
                    type="password"
                    isInvalid={!validOldPWD}
                  />
                  <FormControl.Feedback type="invalid">
                    {oldPWDFeedback}
                  </FormControl.Feedback>
                </Form.Group>
              ) : null}
              <Form.Group className="mb-1">
                {hasLabel && <Form.Label>{textLang.new[lang]}</Form.Label>}
                <Form.Control
                  placeholder={!hasLabel ? textLang.new[lang] : ''}
                  value={password}
                  name="password"
                  onChange={e => setPassword(e.target.value)}
                  type="password"
                  isInvalid={!validPWD}
                />
                <FormControl.Feedback type="invalid">
                  {PWDFeedback}
                </FormControl.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                {hasLabel && (
                  <Form.Label>{textLang.repeatNew[lang]}</Form.Label>
                )}
                <Form.Control
                  placeholder={!hasLabel ? textLang.repeatNew[lang] : ''}
                  value={repeatPassword}
                  name="repeat-password"
                  onChange={e => setRepeatPassword(e.target.value)}
                  type="password"
                  isInvalid={!validRepeatPWD}
                />
                <FormControl.Feedback type="invalid">
                  {repeatPWDFeedback}
                </FormControl.Feedback>
              </Form.Group>

              <Form.Group className="mb-4">
                <Button
                  className="w-100"
                  type="submit"
                  disabled={
                    !password ||
                    !repeatPassword ||
                    (uniqueCode ? false : !OldPassword) ||
                    loadingButton
                  }
                >
                  {textLang.button[lang]}
                </Button>
                {loadingButton ? (
                  <Row className="mt-3">
                    <Col
                      md={6}
                      className="gx-1"
                      style={{ margin: '0 auto', textAlign: 'center' }}
                    >
                      <Spinner animation="border" role="status"></Spinner>
                    </Col>
                  </Row>
                ) : null}
              </Form.Group>
            </Form>
          ) : (
            <>
              <p className="opacity-75 text-center">
                {textLang.okMsg[lang]} <br /> {textLang.okMsg1[lang]}
              </p>
              <div className="d-flex justify-content-center">
                <Link
                  className="btn btn-primary"
                  to={uniqueCode ? '/login' : '/'}
                >
                  {uniqueCode ? 'Login' : 'Home'}
                </Link>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

AddPWDForm.propTypes = {
  hasLabel: PropTypes.bool,
  uniqueCode: PropTypes.string
};

export default AddPWDForm;
