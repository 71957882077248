import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormControl, Spinner, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { ForgotPasswordForm as textLang } from 'staticData/languages';
import AppContext from 'context/Context';

const ForgotPasswordForm = ({ hasLabel }) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  // State
  const [formData, setFormData] = useState({ email: '' });
  const [validMail, setValidMail] = useState(true);
  const [mailFeedback, setMailFeedback] = useState();
  const [signinStatus, setSigninStatus] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const addToForgotPassword = async recaptcha_token => {
    let form = new FormData();
    form.append('mail', formData.email.trim());
    form.append('recaptcha_token', recaptcha_token);
    let addUserResponse = await endpointInterface(
      lang,
      'backend',
      'forgotPassword',
      'post',
      true,
      form
    );
    if (addUserResponse.validResponse) setSigninStatus(true);
    else {
      toast.error(addUserResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
    }
  };

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setLoadingButton(true);
    setValidMail(true);
    setMailFeedback('Mail is not in the correct format');
    var mailRE = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    var valid = true;
    if (!mailRE.test(formData.email)) {
      setValidMail(false);
      valid = false;
    }
    if (valid) {
      // Generate Recaptcha Token & Forgot Password
      window.grecaptcha.enterprise.ready(async () => {
        const recaptcha_token = await window.grecaptcha.enterprise.execute(
          '6LcE9igoAAAAAJ2plIdhi4bvhKr3hn95kyNmxTqc',
          { action: 'forgot_password' }
        );
        addToForgotPassword(recaptcha_token);
      });
    }
    // if (valid) {
    //   setLoadingButton(true);
    //   setSigninStatus(true);
    // }
    else setLoadingButton(false);
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <h3 className={signinStatus ? 'text-center' : null}>
        {textLang.forgotPwd[lang]}
      </h3>
      {!signinStatus ? (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>{textLang.email[lang]}</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? textLang.forgotPwd[lang] : ''}
              value={formData.email}
              name="email"
              onChange={handleFieldChange}
              type="text"
              isInvalid={!validMail}
            />
            <FormControl.Feedback type="invalid">
              {mailFeedback}
            </FormControl.Feedback>
          </Form.Group>

          <Form.Group className="mb-4">
            <Button
              className="w-100"
              type="submit"
              disabled={!formData.email || loadingButton}
            >
              {textLang.sendLink[lang]}
            </Button>
            {loadingButton ? (
              <Row className="mt-3">
                <Col
                  md={6}
                  className="gx-1"
                  style={{ margin: '0 auto', textAlign: 'center' }}
                >
                  <Spinner animation="border" role="status"></Spinner>
                </Col>
              </Row>
            ) : null}
          </Form.Group>
        </Form>
      ) : (
        <p className="opacity-75 text-center">
          {textLang.okMsg[lang]}
          <br />
          {textLang.okMsg1[lang]}
        </p>
      )}
    </>
  );
};

ForgotPasswordForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default ForgotPasswordForm;
